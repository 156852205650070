import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Row, Card, CardText, CardBody, CardTitle, Button } from 'reactstrap'
import ShantanuImage from '../images/shantanu.jpeg'
import { slugify } from '../util/utilityFunctions'
import authors from '../util/authors'

const TeamPage = () => (
  <Layout pageTitle="Our Team">
    <SEO title="Team" />
    <Row className="md-4">
        <div className="col-md-3">
            <img src={ShantanuImage} style={{ maxWidth: '100%' }} alt="Shantanu profile"/>
        </div>
        <div className="col-md-8">
            <Card style={{ minHeight: '100%'}}>
                <CardBody>
                    <CardTitle>{authors[0].name}</CardTitle>
                    <CardText>{authors[0].bio}</CardText>
                    <Button className="text-uppercase" color="primary" href={`/author/${slugify(authors[0].name)}`}>View posts</Button>
                </CardBody>
            </Card>
        </div>
    </Row>
  </Layout>
)

export default TeamPage